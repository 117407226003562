import {computed, reactive, useRoute, watch} from '@nuxtjs/composition-api';

const state = reactive({
    scrollingEnabled: true,
    scrollY: 0,
});

export function useNoScroll() {
    const enableScrolling = () => {
        if (!state.scrollingEnabled) {
            // document.body.style.top = '';
            document.getElementsByTagName( 'html' )[0].classList.remove('no-scroll');
            document.body.classList.remove('no-scroll');

            window.scrollTo(0, state.scrollY);

            state.scrollingEnabled = true;
            state.scrollY = 0;
        }
    }

    const disableScrolling = () => {
        if (state.scrollingEnabled) {
            state.scrollingEnabled = false;
            state.scrollY = window.scrollY;

            // document.body.style.top = `${-state.scrollY}px`;
            document.getElementsByTagName('html')[0].classList.add('no-scroll');
            document.body.classList.add('no-scroll');
        }
    }

    return {
        scrollingEnabled: computed(() => state.scrollingEnabled),
        enableScrolling,
        disableScrolling,
    };
}
