import { VsfContext } from '~/composables/context';
import { Logger } from '~/helpers/logger';
import {
  Cart,
  CartItemInput,
} from '~/modules/GraphQL/types';
import { CustomQuery } from '~/types/core';
import addItemCommandGql from '~/bold/stores/graphql/addItemCommand.gql';

/** Params object used to add items to a cart */
export declare type AddProductsToCartInput = {
  cartId: string;
  cartItems: CartItemInput[];
};

export const addItemCommand = {
  execute: async (
    context: VsfContext,
    {
      product,
      quantity,
      currentCart,
      productConfiguration,
      promotion,
      itemList,
      customQuery,
    },
    i18n: any,
  ) => {
    Logger.debug('[Magento]: Add item to cart', {
      product,
      quantity,
      currentCart,
    });

    const apiState = context.$magento.config.state;
    const cartId = apiState.getCartId();

    if (!product) {
      return;
    }
    // eslint-disable-next-line no-underscore-dangle
    switch (product.__typename) {
      case 'SimpleProduct':

        const simpleCartInput: AddProductsToCartInput = {
          cartId,
          cartItems: [
            {
              quantity,
              sku: product.sku,
              promotion,
              item_list: itemList,
            },
          ],
        };

        const simpleProduct = await context.$magento.api.customQuery({
          query: addItemCommandGql,
          queryVariables: simpleCartInput
        });

        Logger.debug('[Result]:', { data: simpleProduct.data }, i18n.t('An error occurred\'') as string);

        // @ts-ignore
        if (!simpleProduct.data.addProductsToCart) {
          let error = i18n.t('An error occurred\'') as string;
          if (simpleProduct.errors?.length > 0) {
            error = simpleProduct.errors[0].message;
          }
          return [
            null,
            error,
          ];
        }

        // @ts-ignore
        if (simpleProduct.data.addProductsToCart.user_errors.length > 0) {
          return [
            // @ts-ignore
            simpleProduct.data.addProductsToCart.cart as unknown as Cart,
            // @ts-ignore
            (String(simpleProduct.data.addProductsToCart.user_errors[0].message))
          ];
        }

        // @ts-ignore eslint-disable-next-line consistent-return
        return simpleProduct.data.addProductsToCart.cart as unknown as Cart;
      case 'ConfigurableProduct':
        const selectedOptions = Object.values(productConfiguration as object);

        const configurableCartInput: AddProductsToCartInput = {
          cartId,
          cartItems: [
            {
              quantity,
              sku: product.sku,
              selected_options: selectedOptions,
              promotion,
              item_list: itemList,
            },
          ],
        };

        const configurableProduct = await context.$magento.api.addProductsToCart(configurableCartInput, customQuery as CustomQuery);
        Logger.debug('[Result]:', { data: configurableProduct.data });

        if (configurableProduct.data.addProductsToCart.user_errors.length > 0) {
          throw new Error(String(configurableProduct.data.addProductsToCart.user_errors[0].message));
        }

        // eslint-disable-next-line consistent-return
        return configurableProduct.data
          .addProductsToCart
          .cart as unknown as Cart;
      case 'BundleProduct':
        const createEnteredOptions = () =>
        // eslint-disable-next-line implicit-arrow-linebreak
          product.bundle_options.map((bundleOption) => ({
            ...bundleOption,
            value: bundleOption.value.toString(),
          }));

        const bundleCartInput: AddProductsToCartInput = {
          cartId,
          cartItems: [
            {
              quantity,
              sku: product.sku,
              entered_options: createEnteredOptions(),
              promotion,
              item_list: itemList,
            },
          ],
        };

        const bundleProduct = await context.$magento.api.addProductsToCart(bundleCartInput, customQuery as CustomQuery);

        Logger.debug('[Result]:', { data: bundleProduct });

        if (bundleProduct.data.addProductsToCart.user_errors.length > 0) {
          throw new Error(String(bundleProduct.data.addProductsToCart.user_errors[0].message));
        }

        // eslint-disable-next-line consistent-return
        return bundleProduct
          .data
          .addProductsToCart
          .cart as unknown as Cart;
      case 'DownloadableProduct':
        const downloadableCartInput: AddProductsToCartInput = {
          cartId,
          cartItems: [
            {
              quantity,
              sku: product.sku,
              promotion,
              item_list: itemList,
            },
          ],
        };

        const downloadableProduct = await context.$magento.api.addProductsToCart(downloadableCartInput, customQuery as CustomQuery);

        Logger.debug('[Result DownloadableProduct]:', { data: downloadableProduct });

        if (downloadableProduct.data.addProductsToCart.user_errors.length > 0) {
          throw new Error(String(downloadableProduct.data.addProductsToCart.user_errors[0].message));
        }

        // eslint-disable-next-line consistent-return
        return downloadableProduct
          .data
          .addProductsToCart
          .cart as unknown as Cart;
      case 'VirtualProduct':
        const virtualCartInput: AddProductsToCartInput = {
          cartId,
          cartItems: [
            {
              quantity,
              sku: product.sku,
              promotion,
              item_list: itemList,
            },
          ],
        };
        const virtualProduct = await context.$magento.api.addProductsToCart(virtualCartInput, customQuery as CustomQuery);

        Logger.debug('[Result VirtualProduct]:', { data: virtualProduct });

        if (downloadableProduct.data.addProductsToCart.user_errors.length > 0) {
          throw new Error(String(downloadableProduct.data.addProductsToCart.user_errors[0].message));
        }

        // eslint-disable-next-line consistent-return
        return virtualProduct
          .data
          .addProductsToCart
          .cart as unknown as Cart;
      default:
        // eslint-disable-next-line no-underscore-dangle
        throw new Error(`Product Type ${product.__typename} not supported in add to cart yet`);
    }
  },
};

export const addItemsCommand = {
  execute: async (
      context: VsfContext,
      {
        cartItems,
        currentCart,
        customQuery,
      },
      i18n: any,
  ) => {
    Logger.debug('[Magento]: Add items to cart', {
      cartItems,
      currentCart,
    });

    const apiState = context.$magento.config.state;
    const cartId = apiState.getCartId();

    if (!cartItems || cartItems.length === 0) {
      return;
    }

    const addProductsToCartInput: AddProductsToCartInput = {
      cartId,
      cartItems: cartItems.map(({ sku, quantity, productConfiguration, promotion, productType, bundleOptions }) => {
        switch (productType) {
          case 'SimpleProduct':
            return {
              quantity,
              sku: sku,
              promotion,
            };
          case 'ConfigurableProduct':
            const selectedOptions = Object.values(productConfiguration as object);
            return {
              quantity,
              sku: sku,
              selected_options: selectedOptions,
              promotion,
            };
          case 'BundleProduct':
            const createEnteredOptions = () =>
                bundleOptions.map((bundleOption) => ({
                  ...bundleOption,
                  value: bundleOption.value.toString(),
                }));
            return {
              quantity,
              sku: sku,
              entered_options: createEnteredOptions(),
              promotion,
            };
          case 'DownloadableProduct':
          case 'VirtualProduct':
            return {
              quantity,
              sku: sku,
              promotion,
            };
          default:
            throw new Error(`Product Type ${productType} not supported in add to cart yet`);
        }
      }),
    };

    const result = await context.$magento.api.addProductsToCart(addProductsToCartInput, customQuery as CustomQuery);

    Logger.debug('[Result]:', { data: result.data });

    if (result.data.addProductsToCart.user_errors.length > 0) {
      return [
        result.data.addProductsToCart.cart as unknown as Cart,
        String(result.data.addProductsToCart.user_errors[0].message)
      ];
    }

    return result.data.addProductsToCart.cart as unknown as Cart;
  },
};
