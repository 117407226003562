import { computed, ssrRef, useContext } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import categorySearchGql from '~/composables/catalogsearch/searchCategories/search.gql'

export const searchCategories = () => {
    const { app } = useContext();
    const loading = ssrRef(false, `searchCategories-loading`);
    const error = ssrRef(null, `searchCategories-error`);

    const get = async (params) => {
        Logger.debug(`searchCategories`, params);
        let categoryList = null;

        try {
            loading.value = true;
            const { data } = await app.context.$vsf.$magento.api.customQuery({
                query: categorySearchGql,
                queryVariables: {
                    term: params.term
                }
            });

            Logger.debug('[Result searchCategories]:', { data });
            categoryList = data.categorySearch
            error.value = null;
        } catch (exception) {
            error.value = exception;
            console.error(`searchCategories error`, exception);
        } finally {
            loading.value = false;
        }

        return categoryList;
    };

    return {
        get,
        loading: computed(() => loading.value),
        error: computed(() => error.value)
    }
};
