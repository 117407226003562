/* eslint-disable  */


export default {
    "About us":"L’azienda",
    "Account":"Area Utente",
    "Add new address":"Aggiungi nuovo indirizzo",
    "Add to compare":"Aggiungi per confrontare",
    "Add to Wishlist":"Aggiungi alla Wishlist",
    "Additional Information":"Altre informazioni",
    "All Orders":"Tutti gli ordini",
    "Allow order notifications":"Consenti le notifiche degli ordini",
    "Apply":"Applica",
    "Applied Coupon":"Coupon applicato",
    "Attention!":"Attenzione!",
    "Back to home":"Torna alla home page",
    "Back to homepage":"Torna alla home page",
    "Billing":"Fatturazione",
    "Billing address":"Indirizzo di fatturazione",
    "Brand":"Marchio",
    "Cancel":"Cancella",
    "Cart":"Carrello",
    "Categories":"Categorie",
    "Change":"Modifica",
    "If you want to change the password to access your account enter the following information":"Per modificare la password di accesso al proprio account è necessario inserire i seguenti dati",
    "Clear all":"Cancella tutto",
    "Color":"Colore",
    "and agree to receive personalized commercial information from Brand name by email":"e acconsenti a ricevere informazioni commerciali personalizzate dal marchio tramite email",
    "Keep your addresses and contact details updated.":"Mantieni aggiornati i tuoi indirizzi e recapiti.",
    "Contact us":"Contattaci",
    "Continue to billing":"Continua con la fatturazione",
    "Continue to payment":"Continua con il pagamento",
    "Continue to shipping":"Continua con la spedizione",
    "Cookies Policy":"cookie policy",
    "Create an account":"Crea un account",
    "Customer Reviews":"Recensioni dei clienti",
    "Customer service":"Assistenza clienti",
    "Date":"Data",
    "Default Billing Address":"Indirizzo di fatturazione predefinito",
    "Default Shipping Address":"Indirizzo di spedizione predefinito",
    "Delete":"Cancella",
    "Departments":"Reparti",
    "Description":"Descrizione",
    "Check the details and status of your orders in the online store. You can also cancel your order or request a return.":"Controlla i dettagli e lo stato dei tuoi ordini nel negozio online. Puoi anche annullare l'ordine o richiedere un reso.",
    "Discount":"Sconto",
    "Done":"Fatto",
    "Download":"Scarica",
    "Download all":"Scarica tutto",
    "Edit":"Modifica",
    "Email address":"Indirizzo email",
    "Empty bag":"Carrello vuoto",
    "Enjoy these perks with your free account!":"Goditi questi vantaggi con il tuo account gratuito!",
    "Enter promo code":"Inserisci il codice promozionale",
    "Your feedback is important to us. Let us know what we could improve.":"La tua opinione è importante per noi. Dicci cosa potremmo migliorare.",
    "Feel free to edit any of your details below so your account is always up to date":"Puoi modificare i tuoi dati in modo che il tuo account sia sempre aggiornato",
    "Filters":"Filtri",
    "Find out more":"Scopri di più",
    "First Name":"Nome",
    "If you can’t remember your password you can reset it.":"Se non ricordi la password puoi reimpostarla.",
    "Email you are using to sign in:":"Email che stai utilizzando per accedere:",
    "Thanks! If there is an account registered with the {0} email you will find message with a password reset link in your inbox.":"Grazie. Riceverai una email al tuo indirizzo di posta contenente un link per reimpostare la password. Se il messaggio non arriva, prova con un altro indirizzo email che potresti aver usato in fase di registrazione",
    "Forgotten password?":"Hai dimenticato la password?",
    "Go back":"Indietro",
    "Go back shopping":"Torna agli acquisti",
    "Go back to shop":"Torna agli acquisti",
    "Go to checkout":"Vai alla cassa",
    "Guarantee":"Garanzia",
    "Help":"Assistenza",
    "Help & FAQs":"Assistenza e FAQ",
    "hide":"nascondi",
    "Home":"Home page",
    "I agree to":"Accetto di",
    "I confirm subscription":"Confermo l'iscrizione",
    "I want to create an account":"Desidero creare un account",
    "You can login to your account using e-mail and password defined earlier. On your account you can edit your profile data check history of transactions edit subscription to newsletter.":"Puoi accedere al tuo account utilizzando email e password definite in precedenza. Sul tuo account puoi modificare i dati del tuo profilo, controllare lo storico delle transazioni e modificare l'iscrizione alla newsletter.",
    "It was not possible to request a new password please check the entered email address.":"Non è possibile richiedere una nuova password. Controlla l'indirizzo email inserito.",
    "Item":"Articolo",
    "Items":"Articoli",
    "Last Name":"Cognome",
    "Let’s start now – we’ll help you.":"Iniziamo - ti forniremo la nostra assistenza.",
    "Log into your account":"Accedi al tuo account",
    "Login":"Login",
    "login in to your account":"accedi al tuo account",
    "Looks like you haven’t added any items to the bag yet. Start shopping to fill it in.":"Non hai ancora aggiunto alcun articolo nel carrello. Inizia a fare acquisti per riempirlo.",
    "Looks like you haven’t added any items to the Wishlist.":"Non hai ancora aggiunto alcun articolo alla tua Wishlist.",
    "Make an order":"Effettua un ordine",
    "Manage all the addresses you want (work place home address...) This way you won't have to enter the address manually with each order.":"Gestisci tutti gli indirizzi che desideri (indirizzo del posto di lavoro, di casa...) In questo modo non dovrai inserire manualmente l'indirizzo ad ogni ordine.",
    "Manage all the billing addresses you want (work place home address...) This way you won't have to enter the billing address manually with each order.":"Gestisci tutti gli indirizzi di fatturazione che desideri (indirizzo del posto di lavoro, di casa...) In questo modo non dovrai inserire manualmente l'indirizzo di fatturazione ad ogni ordine.",
    "Manage all the shipping addresses you want (work place home address...) This way you won't have to enter the shipping address manually with each order.":"Gestisci tutti gli indirizzi di spedizione che desideri (indirizzo del posto di lavoro, di casa...) In questo modo non dovrai inserire manualmente l'indirizzo di spedizione ad ogni ordine.",
    "Match it with":"Abbinalo a",
    "Menu":"Menù",
    "My billing and shipping address are the same":"Il mio indirizzo di fatturazione e di spedizione è lo stesso",
    "My Cart":"Carrello",
    "Don't have an account yet?":"Non  hai ancora un account?",
    "or":"o",
    "or fill the details below":"oppure compila i seguenti dati",
    "Order ID":"ID ordine",
    "Order information":"Informazioni sull’ordine",
    "Order No.":"Numero d’ordine",
    "Order summary":"Riepilogo dell’ordine",
    "Other products you might like":"Altri prodotti che potrebbero interessarti",
    "Out of stock":"Esaurito",
    "Password successfuly changed. You can now go back to homepage and sign in.":"La password è stata modificata correttamente. Ora puoi tornare alla home page e iscriverti.",
    "Pay for order":"Paga l’ordine",
    "Payment":"Pagamento",
    "Payment & Delivery":"Pagamento e Consegna",
    "Payment Method":"Metodo di pagamento",
    "Payment Methods":"Metodi di pagamento",
    "Personal details":"Dati personali",
    "Please type your current password to change your email address.":"Digita la tua password attuale per cambiare l’indirizzo email.",
    "Price":"Prezzo",
    "Privacy":"Privacy",
    "Privacy Policy":"Informativa sulla privacy",
    "The Privacy Policy":"l'informativa sulla privacy",
    "Product":"Prodotto",
    "Product suggestions":"Proposte di prodotto",
    "Products":"Prodotti",
    "Products found":"Prodotti trovati",
    "Purchase terms":"Condizioni di acquisto",
    "Quality in the details":"Qualità nei dettagli",
    "Quantity":"Quantità",
    "Read all reviews":"Leggi tutte le recensioni",
    "I have read and understand the":"Ho letto e compreso che",
    "Read reviews":"Leggi le recensioni",
    "Register":"Registra",
    "Register today":"Registrati ora",
    "Remove":"Rimuovi",
    "Remove Address":"Rimuovi l’indirizzo",
    "Remove from Wishlist":"Rimuovi la Wishlist",
    "Repeat Password":"Ripeti la password",
    "Reset Password":"Reimposta la password",
    "Review my order":"Recensisco il mio ordine",
    "Same as shipping address":"Lo stesso dell’indirizzo di spedizione",
    "Save changes":"Salva le modifiche",
    "Save for later":"Salva per dopo",
    "Save Password":"Salva la password",
    "BottomNavigationHomeLabel":"Home",
    "BottomNavigationUserAreaLabel":"Area Utente",
    "BottomNavigationSearchLabel":"Ricerca",
    "Search":"Cerca",
    "Search for items":"Cerca articoli",
    "Search results":"Cerca risultati",
    "Sections that interest you":"Sezioni che ti interessano",
    "See all results":"Vedi tutti i risultati",
    "See more":"Vedi di più",
    "Select payment method":"Seleziona metodo di pagamento",
    "Select shipping method":"Seleziona metodo di spedizione",
    "Send my feedback":"Invia la mia opinione",
    "Set up your newsletter and we will send you information about new products and trends from the sections you selected every week.":"Imposta la tua newsletter e ti invieremo informazioni sui nuovi prodotti e le nuove tendenze dalle sezioni che hai selezionato ogni settimana.",
    "Shipping":"Spedizione",
    "Shipping address":"Indirizzo di spedizione",
    "Shipping details":"Dettagli di spedizione",
    "Shipping method":"Metodo di spedizione",
    "Show":"Mostra",
    "show more":"mostra di più",
    "Show on page":"Mostra nella pagina",
    "Sign in":"Iscriviti",
    "Size guide":"Guida alle taglie",
    "Sign Up for Newsletter":"Iscriviti alla newsletter",
    "Ordina per":"Ordina per",
    "Default":"Predefinito",
    "Name A-Z":"Nome A-Z",
    "Name Z-A":"Nome Z-A",
    "Price from high to low":"Prezzo da alto a basso",
    "Price from low to high":"Prezzo da basso a alto",
    "Start shopping":"Inizia i tuoi acquisti",
    "Status":"Stato",
    "Subscribe":"Iscriviti",
    "Subscribe to newsletter":"Iscriviti alla newsletter",
    "After signing up for the newsletter you will receive special offers and messages from VSF via email. We will not sell or distribute your email to any third party at any time. Please see our {0}.":"Dopo l'iscrizione alla newsletter riceverai offerte speciali e messaggi da VSF via email. La tua email non sarà né venduta né comunicata a terzi in nessun momento. Consulta il nostro {0}.",
    "Subtotal":"Subtotale",
    "Subtotal price":"Subtotale",
    "You have successfully placed the order. You can check status of your order by using our delivery status feature. You will receive an order confirmation e-mail with details of your order and a link to track its progress.":"L’ordine è stato effettuato correttamente. Puoi controllare lo stato del tuo ordine utilizzando la nostra funzione di stato di avanzamento della consegna. Riceverai un'email di conferma dell'ordine con i dettagli del tuo ordine e un link per seguirne lo stato di avanzamento.",
    "Terms and conditions":"Termini e condizioni",
    "Thank you":"Grazie",
    "If the message is not arriving in your inbox try another email address you might’ve used to register.":"Se non trovi il messaggio nella tua casella di posta, prova un altro indirizzo email che potresti aver usato per registrarti.",
    "Total":"Totale",
    "Order Total":"Totale ordine",
    "Total items":"Articoli totali",
    "Total price":"Prezzo totale",
    "Update password":"Aggiorna password",
    "Update personal data":"Aggiorna i dati personali",
    "At Brand name we attach great importance to privacy issues and are committed to protecting the personal data of our users. Learn more about how we care and use your personal data in the":"Noi di Nome marchio attribuiamo grande importanza alle questioni relative alla privacy e ci impegniamo a proteggere i dati personali dei nostri utenti. Scopri di più su come gestiamo e utilizziamo i tuoi dati personali in",
    "User Account":"Account utente",
    "View":"Visualizza",
    "View details":"Visualizza i dettagli",
    "Who we are":"Chi siamo",
    "Wishlist":"Wishlist",
    "You added {product} to your shopping cart.":"Hai aggiunto {prodotto} al carrello.",
    "You are not authorized please log in":"Non sei autorizzato, effettua il login",
    "You can unsubscribe at any time":"Puoi disiscriverti in qualsiasi momento",
    "You currently have no orders":"Attualmente non hai ordini in corso",
    "You haven’t searched for items yet.":"Non hai ancora cercato alcun articolo.",
    "Your bag is empty":"Il tuo carrello è vuoto",
    "Your current email address is":"Il tuo indirizzo email attuale è",
    "Your email":"La tua email",
    "The email field must be a valid email":"Nel campo dell’email deve essere inserita un'email valida",
    "The pec field must be a valid email":"Nel campo dell’pec deve essere inserita un'email valida",
    "You have submitted no reviews'":"Non hai inviato alcuna recensione'",
    "A customer with the same email address already exists in an associated website.":"Esiste già un account associato a questo indirizzo email. Verifica che la email sia inserita correttamente o prova con un altro indirizzo.",
    "Invalid email":"email non valida",
    "Colorful summer dresses are already in store":"I coloratissimi abiti estivi sono già in negozio",
    "Learn more":"Scopri di più",
    "Shop now":"Acquista adesso",
    "Subscribe to Newsletters":"Iscriviti alle newsletter",
    "Be aware of upcoming sales and events. Receive gifts and special offers":"Rimani aggiornato sulle prossime vendite ed eventi. Ricevi regali e offerte speciali",
    "Download our application to your mobile":"Scarica la nostra app sul tuo cellulare",
    "My Account":"Il mio account",
    "My profile":"Il mio profilo",
    "Personal Details":"Dati personali",
    "Addresses details":"Dettagli degli indirizzi",
    "My newsletter":"La mia newsletter",
    "Log out":"Log out",
    "My reviews":"Le mie recensioni",
    "Order history":"Storico degli ordini",
    "Order details":"Dettagli dell’ordine",
    "My wishlist":"La mia wishlist",
    "Password change":"Modifica della password",
    "Personal data":"Dati personali",
    "Your e-mail":"La tua email",
    "Current Password":"Password attuale",
    "You are not authorized please log in.":"Non sei autorizzato, effettua il login.",
    "Go To Product":"Vai al prodotto",
    "Go to homepage":"Torna alla homepage",
    "Change to list view":"Passa alla visualizzazione dell’elenco",
    "Change to grid view":"Passa alla visualizzazione della griglia",
    "Returns":"Resi",
    "My orders":"I miei ordini",
    "Add the address":"Aggiungi l’indirizzo",
    "Set as default shipping":"Imposta come spedizione predefinita",
    "Set as default billing":"Imposta come fatturazione predefinita",
    "House/Apartment number":"N° Civico",
    "Street Name":"Indirizzo",
    "City":"Città",
    "State/Province":"Stato/Provincia",
    "Province":"Provincia",
    "Zip-code":"CAP",
    "Country":"Paese",
    "Phone number":"Numero di telefono",
    "Please select a country first":"Seleziona prima un paese",
    "This field is required":"Campo obbligatorio",
    "The field should have at least 2 characters":"Il campo deve contenere almeno 2 caratteri",
    "The field should have at least 4 characters":"Il campo deve contenere almeno 4 caratteri",
    "The field should have at least 8 characters":"Il campo deve contenere almeno 8 caratteri",
    "This field should have 5 numbers": "Il campo deve contenere 5 numeri",
    "This field should have 11 numbers": "Il campo deve contenere 11 numeri",
    "The fiscal code is invalid": "Codice fiscale non valido",
    "This field must contain at least 1 letter and 1 number": "Il campo deve contenere almeno 1 lettera e 1 numero",
    "This field should have exactly 7 characters": "Il campo deve contenere esattamente 7 caratteri",
    "The field should have exactly 7 characters":"Il campo deve contenere 7 caratteri alfanumerici",
    "Your wishlist is empty": "La tua lista dei desideri è vuota",
    "You already subscribed to our newsletter.": "Questa email è già iscritta alla newsletter",
    "You successfully subscribed to our newsletter!":"Iscrizione alla newsletter avvenuta con successo",
    "You will receive an email confirming your subscription to the newsletter.":"Riceverai una email in cui confermare la tua iscrizione alla newsletter.",
    "Details and status orders":"Dettagli e stato degli ordini",
    "New Password":"Nuova password",
    "New Products":"Nuovi prodotti",
    "There are no shipping methods available for this country. We are sorry please try with different country.":"Non vi sono metodi di spedizione disponibili per questo paese. Siamo spiacenti, prova con un altro paese.",
    "There was some error while trying to fetch shipping methods. We are sorry please try with different shipping details.":"Si è verificato un errore durante il tentativo di recuperare i metodi di spedizione. Siamo spiacenti, prova con altri dettagli di spedizione.",
    "There was some error while trying to select this shipping method. We are sorry please try with different shipping method.":"Si è verificato un errore durante il tentativo di selezionare il metodo di spedizione. Siamo spiacenti, prova con un altro metodo di spedizione.",
    "We can't find products matching the selection.":"Impossibile trovare prodotti corrispondenti alla selezione.",
    "Page not found'":"Pagina non trovata'",
    "Back to Home page'":"Torna alla Home page'",
    "An error occurred'":"Si è verificato un errore'",
    "All {categoryName}":"Tutte {Nomecategoria}",
    "Show more":"Mostra di più",
    "Show less":"Mostra di meno",
    "Yes":"Sì",
    "No":"No",
    "Apply filters":"Applica i filtri",
    "The coupon code isn't valid. Verify the code and try again.":"Il codice del coupon non è valido. Controlla il codice e riprova.",
    "From":"Da",
    "To":"A",
    "Your customization":"La tua personalizzazione",
    "Passwords don't match":"Le password non corrispondono",
    "The password must be at least 8 characters long and must contain at least: 1 uppercase or lowercase letter 1 number or one special character (E.g.  . _ & ? etc)":"La password deve contenere almeno 8 caratteri tra cui almeno: 1 lettera maiuscola o minuscola 1 numero o un carattere speciale (Es. . _ & ? ecc)",
    "Show all products":"Mostra tutti i prodotti",
    "Select previously saved address":"Seleziona l’indirizzo salvato in precedenza",
    "Enter different address":"Inserisci altri indirizzi",
    "You must confirm your account. Please check your email for the confirmation link.":"Registrazione avvenuta con successo. Ora puoi accedere alla tua area personale e continuare con i tuoi acquisti su eUnica!",
    "Change Store":"Cambia negozio",
    "Choose Currency":"Seleziona la valuta",
    "Add a review":"Aggiungi una recensione",
    "Add to cart":"Aggiungi al carrello",
    "Title":"Titolo",
    "Name":"Nome",
    "Review":"Recensione",
    "Add review":"Aggiungi una recensione",
    "Are you sure you would like to remove this item from the shopping cart?":"Sei sicuro di voler rimuovere questo articolo dal carrello?",
    "Your cart is empty":"Il carrello è vuoto",
    "Are you sure?":"Sei sicuro?",
    "{0} has been successfully removed from your cart":"{0} è stato rimosso correttamente dal carrello",
    "Amount":"Importo",
    "Thank you for your order!":"Grazie per il tuo ordine!",
    "Your Purchase":"I tuoi acquisti",
    "Primary contacts for any questions":"Contatti principali per eventuali domande",
    "Your account":"Il tuo account",
    "What can we improve":"Cosa possiamo migliorare",
    "Payment date":"Data di pagamento",
    "The user password was changed successfully updated!":"La password utente è stata modificata e correttamente aggiornata!",
    "The user account data was successfully updated!":"I dati dell'account utente sono stati aggiornati correttamente!",
    "Cosa stai cercando?":"Cosa stai cercando?",
    "Chi siamo":"Chi siamo",
    "Saldo punti":"Saldo punti",
    "Rimedi per":"Rimedi per",
    "Read more":"Leggi di più",
    "Questo sito è protetto da reCAPTCHA e dalle":"Questo sito è protetto da reCAPTCHA e dalle",
    "Privacy Policy e Termini e Condizioni di Google":"Informativa sulla Privacy e Termini e Condizioni di Google",
    "Please agree with the privacy policy":"Accetta l’informativa sulla privacy",
    "Our categories":"Le nostre categorie",
    "Servizi eUnica":"Servizi eUnica",
    "Start searching":"Inizia la ricerca",
    "Error":"Errore",
    "You have been logged in!":"Hai effettuato l'accesso!",
    "You have been logged out.":"Hai effettuato la disconnessione!",
    "You successfully submitted your review!":"La tua recensione è stata inviata correttamente!",
    "Aggiungi al carrello":"Aggiungi al carrello",
    "Aggiunto al carrello":"Aggiunto al carrello",
    "Acquista":"Acquista",
    "Acquistato":"Acquistato",
    "Codeice:":"Codice:",
    "All products":"Tutti i prodotti",
    "Update the address":"Aggiorna l’indirizzo",
    "Read less":"Leggi di meno",
    "E-mail":"Email",
    "01 - January":"01 - Gennaio",
    "02 - February":"02 - Febbraio",
    "03 - March":"03 - Marzo",
    "04 - April":"04 - Aprile",
    "05 - May":"05 - Maggio",
    "06 - June":"06 - Giugno",
    "07 - July":"07 - Luglio",
    "08 - August": "08 - Agosto",
    "09 - September":"09 - Settembre",
    "10 - October":"10 - Ottobre",
    "11 - November":"11 - Novembre",
    "12 - December":"12 - Dicembre",
    "Your shopping cart is empty":"Il tuo carrello è vuoto",
    "Continue shopping on eUnica":"Continua con lo shopping su eUnica",
    "La pagina che stai cercando probabilmente non esiste o ha cambiato nome. Ti invitiamo a verificare l’indirizzo da te inserito o cercare il prodotto nell’apposita area di ricerca":"La pagina che stai cercando probabilmente non esiste o ha cambiato nome. Ti invitiamo a verificare l’indirizzo da te inserito o cercare il prodotto nell’apposita area di ricerca",
    "Personal area":"Area personale",
    "Log in to your personal area to view the status of your orders":"Accedi alla tua area personale per visualizzare lo stato dei tuoi ordini",
    "Proceed with the registration to get the benefits that eUnica offers you":"Procedi con la registrazione per ottenere i vantaggi che ti offre eUnica",
    "Register to get special offers for new eUnica customers!":"Registrati per ottenere le offerte riservate per i nuovi clienti eUnica!",
    "One or more products are out of stock, please check your cart.":"Uno o più prodotti sono esauriti, controlla il carrello",
    "Remedies for":"Rimedi per",
    "What are you searching for?":"Cosa stai cercando?",
    "Highlighted categories":"Categorie in evidenza",
    "Payment successful":"Pagamento avvenuto con successo",
    "Sort by":"Ordina per",
    "Code:":"CODICE:",
    "Questo sito è protetto da reCAPTCHA, e dalle":"Questo sito è protetto da reCAPTCHA, e dalle",
    "Dosaggio e posologia":"Dosaggio e posologia",
    "Sovradosaggio":"Sovradosaggio",
    "Controindicazioni":"Controindicazioni",
    "Effetti collaterali":"Effetti collaterali",
    "Gravidanza":"Gravidanza",
    "Altro":"Altro",
    "Avvertenze Speciali":"Avvertenze Speciali",
    "Composizione":"Composizione",
    "Principio attivo":"Principio attivo",
    "Forma farmaceutica":"Forma farmaceutica",
    "Contenitore":"Contenitore",
    "Condizione di conservazione":"Condizione di conservazione",
    "Foglio Illustrativo":"Foglio Illustrativo",
    "Proceed with the purchase":"Procedi con l’acquisto",
    "Vuoi applicare un codice promo?":"Vuoi applicare un codice promo?",
    "Continue":"Continua",
    "Annulla":"Annulla",
    "Acquista ora":"Acquista ora",
    "Successfully placed order":"Pagamento avvenuto con successo. Riceverai i dettagli del tuo ordine via email.",
    "Only ":"Solo ",
    " item available.":" articolo disponibile.",
    " items available.":" articoli disponibili.",
    "No results":"Siamo spiacenti ma la ricerca non ha prodotto risultati.",
    "Cliccando sul pulsante ":"Cliccando sul pulsante ",
    "continua ":"continua ",
    "procederai senza registrazione come ":"procederai senza registrazione come ",
    "utente ospite":"utente ospite.",
    "Please provide a valid phone number":"Please provide a valid phone number",
    "Riepilogo ordini":"Riepilogo ordini",
    "Qnt: ":"Quantità: ",
    "forgotPasswordConfirmation": "Grazie. Riceverai una email al tuo indirizzo di posta contenente un link per reimpostare la password. Se il messaggio non arriva, prova con un altro indirizzo email che potresti aver usato in fase di registrazione.",
    "Thank You Inbox": "If the message is not arriving in your inbox, try another email address you might’ve used to register.",
    "Passwords do not match":"Le password non coincidono",
    "Promo ":"Promo ",
    "Password":"Password",
    "The user password could not be updated, please check your email again.":"La password non può essere aggiornata. Assicurati di avere inserito la email corretta",
    "Select invoice type.":"Seleziona il tipo di ricevuta desiderato.",
    "In your account you can download the invoice relating to your order (remember that it is not valid for tax deduction)":"Nella tua area personale potrai scaricare il documento d’acquisto relativo al tuo ordine (ricorda che <strong>non è valido</strong> per la detrazione fiscale)",
    "Authorize eUnica to transmit your data to the Revenue Agency for the tax deduction of your health care expenses when filing your income tax return":"Con questa opzione autorizzi eUnica a trasmettere i tuoi dati all’Agenzia delle Entrate per la detrazione fiscale delle tue spese sanitarie in sede di dichiarazione dei redditi",
    "Request the issuance of a regular invoice for VAT number holders (you can enter a mandatory field choosing between SDI or PEC) or natural persons (for which it is mandatory to enter the Tax Code)":"Con questa opzione richiedi l’emissione di regolare fattura per i titolari di Partita Iva (puoi inserire un campo <strong>obbligatorio a scelta tra SDI o PEC)</strong> o persone fisiche (per cui è <strong>obbligatorio inserire il Codice Fiscale)</strong>",
    "Fiscal Code":"Codice Fiscale",
    "Company Region (or Name and Surname)":"Ragione Sociale (o Nome e Cognome)",
    "VAT number":"Partita IVA (o Codice Fiscale)",
    "SDI":"SDI",
    "PEC":"PEC",
    "Expiration Date":"Data di scadenza",
    "What is this?":"Cos’è questo?",
    "save my card for further use":"salva la mia carta per usarla in futuro",
    "Invalid credit card number":"Numero di carta di credito non valido",
    "This field only allows numbers":"Questo campo consente solo l’inserimento di numeri",
    "Feel free to edit":"Modifica i tuoi dati personali",
    "Use your personal data":"Utilizzo dei dati personali",
    "Create an account on the store":"Crea un account",
    "Login on the store":"Accesso al negozio",
    "The account sign-in was incorrect or your account is disabled temporarily. Please wait and try again later.":"Credenziali di accesso errate oppure il tuo account è stato temporaneamente sospeso. Per favore riprova controllando che nome utente e password siano inseriti correttamente.",
    "Terms of use":"Condizioni di utilizzo",
    "Terms of sale":"Condizioni di vendita",
    "Sort: Default":"Ordina: Predefinito ",
    "Sort: Name A-Z":"Ordina: Nome A-Z",
    "Sort: Name Z-A":"Ordina: Nome Z-A",
    "Sort: Price from low to high":"Ordina: Prezzo da basso a alto",
    "Sort: Price from high to low":"Ordina: Prezzo da alto a basso",
    "Forgot Password":"Password dimenticata",
    "Change password your account":"Cambia la password del tuo account",
    "Please enter your email address below to receive a password reset link.":"Inserisci l’indirizzo email per ricevere un link per la reimpostazione della password.",
    "If there is an account associated with “e-mail address” you will receive an email with a link to reset your password.":"Se esiste un account associato all’ “indirizzo email”, riceverai un’email con un link per reimpostare la password.",
    "The password must be at least 8 characters long and must contain at least: 1 uppercase or lowercase letter, 1 number, or one special character (E.g. , . _ & ? etc)":"La password deve contenere almeno 8 caratteri tra cui almeno:1 lettera maiuscola o minuscola, 1 numero o un carattere speciale (ad esempio , ._ & ? ecc.)",
    "Login on the store checkout":"Effettua Login",
    "Applica codice promo":"Applica codice promo",
    "Show filters":"Mostra più",
    "Hide filters":"Mostra meno",
    "No account":"Non hai un account?",
    "Info prodotto":"Info prodotto",
    "cancelCartAction":"Annulla",
    "Yes, continue":"Si, procedi",
    "Manage addresses":"Gestione indirizzi",
    "Address":"Indirizzo",
    "Add to Cart":"Carrello",
    "Basket":"Carrello",
    "Unavailable":"Non disponibile",
    "Currently not available":"Al momento non disponibile",
    "At the moment the product is not in our warehouses. Please check this page in the future to see if the item you selected is available for purchase.":"Al momento il prodotto non si trova nei nostri magazzini. Consulta in futuro questa pagina per vericare che l'articolo da te selezionato sia disponibile per l'acquisto.",
    "Preferred privacy":"Preferenze privacy",
    "Per il prodotto ":"Per il prodotto ",
    "  con SKU ":"  con SKU ",
    " abbiamo aggiornato la quantità da te inserita nel carrello secondo la disponibilità degli articoli in giacenza presso i nostri magazzini (":" abbiamo aggiornato la quantità da te inserita nel carrello secondo la disponibilità degli articoli in giacenza presso i nostri magazzini (",
    ").":").",
    "Il prodotto ":"Il prodotto ",
    " con SKU ":" con SKU ",
    " è esaurito, pertanto lo abbiamo rimosso dal carrello.":" è esaurito, pertanto lo abbiamo rimosso dal carrello.",
    "Credit Card":"Carta di credito",
    "Credit Card Number":"Numero di carta di credito",
    "Month":"Mese",
    "Year":"Anno",
    "Card Verification Number":"Numero di verifica della carta (CVV o CVC)",
    "Customer Name":"La tua nome",
    "Customer Email":"La tua email",
    "Contact details updated":"Aggiorna i dettagli dei tuoi contatti",
    "Ho letto e compreso":"Ho letto e compreso",
    "i termini e le condizioni di vendita":"i termini e le condizioni di vendita",
    "Desidero ricevere informazioni commerciali e promozionali autorizzando il trattamento dei miei dati ai fini di marketing e profilazione":"Desidero ricevere informazioni commerciali e promozionali autorizzando il trattamento dei miei dati ai fini di marketing e profilazione",
    "I have read and understood":"Ho letto e compreso",
    "Read and understand":"Ho preso visione ed accetto ai fini di profilazione e marketing il trattamento dei miei dati contenuti nell'",
    "and":"e nella",
    "[Newsletter] Commercial information":"",
    "Your Name":"Nome del titolare della carta",
    "Search term:":"Risultati ricerca per:",
    "Show all results":"Mostra tutti i risultati",
    "This address doesn't exist, please check your postcode, city and province":"L'indirizzo inserito risulta inesistente. Controlla che il CAP, la città e la provincia siano scritti correttamente.",
    "Your payment is being processed":"Il pagamento è in corso di elaborazione",
    "Page in maintenance":"Sito in manutenzione",
    "Apology under maintenance":"Ci scusiamo per l’inconveniente ma stiamo lavorando per migliorare il nostro servizio.<br>Il negozio eUnica tornerà al più presto disponibile.",
    "Order Comment":"Note per la spedizione (max 80 caratteri)",
    "Street Name + House/Apartment number":"Indirizzo + N° Civico",
    "The field cannot contain more than 80 characters":"Il testo inserito supera gli 80 caratteri.",
    "Pay with {method}":"Paga con {method}",
    "Select one of the payment methods to make your purchase":"Seleziona uno dei metodi di pagamento per effettuare il tuo acquisto",
    "easynolo_bancasellapro_paypal_description": "Accetta i termini e le condizioni di vendita. Cliccando “Acquista ora” aprirai la schermata di pagamento di Paypal.",
    "shippingDescription":"Per migliorare la spedizione, compilate ogni campo solo con l’informazione richiesta e definite sempre il tipo di indirizzo (via, vicolo, viale, piazza, ecc.). Il <strong>numero civico </strong> è importante inserirlo solo nel campo riservato. Qualsiasi altro tipo di informazione potete inserirla dentro “<strong>Note per la spedizione</strong>”.",
    "My Appointments":"I miei appuntamenti",
    "Return to services":"Torna ai servizi",
    "Select a service":"Seleziona il servizio",
    "Select one of the services you want to book":"Seleziona uno dei servizi che desideri prenotare",
    "Appointment confirmed": "Appuntamento confermato.",
    "You will receive a summary email with the details of your appointment. For any change you can modify or cancel your reservation in the tab below.": "Riceverai una mail riepilogativa con i dettagli del tuo appuntamento. Per qualsiasi variazione puoi modificare o cancellare la tua prenotazione nella scheda sottostante.",
    "Appointment successfully updated!": "Appuntamento aggiornato con successo.",
    "Appointment not found": "Appuntamento non trovato",
    "Appointment!": "Appuntamento",
    "Booked service:": "Servizio prenotato:",
    "At": "Presso",
    "On": "Il",
    "from ": "alle ore ",
    "Choose new timeslot": "Scegli un nuovo orario",
    "Appointment successfully deleted!": "Appuntamento eliminato con successo.",
    "Create new appointment": "Crea appuntamento",
    "Overview": "Lista appuntamenti",
    "Reservation summary": "Riepilogo prenotazione",
    "Confirm": "Conferma",
    "Select day and time": "Seleziona il giorno e orario",
    "Back to services" :"Torna ai servizi",
    "Next": "Avanti",
    "Non-registered user": "Utente non registrato",
    "Registered user": "Utente registrato",
    "Make the registration": "Effettua la registrazione",
    "Back to calendar": "Torna al calendario",
    "Update appointment": "Aggiornamento appuntamento",
    "Schedules available": "Orari disponibili",
    "selected date": "data selezionata",
    "Additional notes (optional)": "Note aggiuntive (opzionale)",
    "Insert text here": "Inserisci qui testo",
    "Select pharmacy": "Seleziona la farmacia",
    "Select one of the Unica Pharmacies where you would like to use the service": "Seleziona una delle Farmacie Unica in cui desideri usufuire del servizio",
    "Select the service": "Seleziona il servizio",
    "Select one of the services you want": "Seleziona uno dei servizi che desideri",
    "Book": "Prenota",
    "Appointment": "Appuntamento",
    "View appointment": "Visualizza appuntamento",
    "Telephone": "Telefono",
    "The telephone number is invalid": "Numero di telefono non valido",
    "If you have already registered, select the “registered user“ option to automatically log in and continue with your reservation": "Se hai già effettuato la registrazione, seleziona l’opzione “utente registrato” per effettuare in automatico la login e proseguire con la prenotazione",
    "Are you sure that you want to cancel the appointment?" :"Sei sicuro di voler annullare l'appuntamento?",
    "Yes, proceed": "Si, procedi",
    "Activate Carta Unica": "Attivazione Carta Unica",
    "Card activated": "Carta attivata",
    "Card number": "Numero della carta",
    "Points balance Carta Unica": "Saldo Punti Carta Unica",
    "points": "punti",
    "Your card has been successfully linked to your account!": "La tua carta è stata collegata con successo al tuo account.",
    "Your have deactivated your loyalty card!": "Hai disattivato la tua carta fedeltà.",
    "My appointments": "I miei appuntamenti",
    "Service fee:": "Costo del servizio:",
    "You have successfully registered for a new loyalty account!":"Ti sei registrato al programma fedeltà!",
    "You do not have enough points to choose a reward yet.":"Non hai ancora abbastanza punti per scegliere un voucher.",
    "See details":"Dettagli ordine",
    "Reorder":"Nuovo ordine",
    "Details":"Dettagli",
    "Reorder this order":"Acquista di nuovo",
    "The items are added to the cart!":"Gli articoli sono stati aggiunti al carrello.",
    "Could not add all products to the cart.":"Impossibile aggiungere tutti gli articoli al carrello."
};
