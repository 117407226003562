import { computed, ssrRef, useContext } from '@nuxtjs/composition-api';

export const useGetCacheVersion = () => {
    const context = useContext();
    const cacheVersion = ssrRef(null, 'getCacheVersion-cacheVersion');

    if (context.ssrContext)
        cacheVersion.value = context.ssrContext.storyblokCacheVersion;

    return {
        cacheVersion
    }
};
