export default `
    query uniCrossSellProducts($input: CrossSellProductsInput!){
    uniCrossSellProducts(input: $input) {
            items {
            __typename
            uid
            categories {
                breadcrumbs {
                    category_id
                    category_name
                    category_level
                    category_url_key
                    category_url_path
                }
                name
                uid
                url_path
                url_suffix
            }
            sku
            name
            stock_status
            only_x_left_in_stock
            is_promo_sale_only
            availability_code
            thumbnail {
                url
                position
                disabled
                label
            }
            url_key
            url_rewrites {
                url
            }
            recommended_price
            last_known_price {
                currency
                value
            }
            mpbrand {
                value
            }
            bold_gallery_images {
                magento_roles,
                    label,
                    position,
                    formats {
                    mega,
                        large,
                        medium,
                        small,
                        tiny
                }
            }
            price_range {
                maximum_price {
                    final_price {
                        currency
                        value
                    }
                    regular_price {
                        currency
                        value
                    }
                }
                minimum_price {
                    final_price {
                        currency
                        value
                    }
                    regular_price {
                        currency
                        value
                    }
                }
            }
        }
    }
}
`;
