//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import SvgImage from '~/components/General/SvgImage.vue';

export default {
  name: "BoldButton",
  components: {SvgImage},
  props: {
    text: {
      type: String,
      default: ""
    },
    noTextOnMobile: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: "lg"
    },
    disabled: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ""
    },
    iconToolTipAmount: {
      type: Number,
      default: -1
    },
    loading: {
      type: Boolean,
      default: false
    },
    forceHoverState: {
      type: Boolean,
      default: false
    },
    growerClasses: {
      type: String,
      default: ""
    },
    iconClasses: {
      type: String,
      default: ""
    },
    textClasses: {
      type: String,
      default: ""
    },
    htmlElement: {
      type: String,
      default: "div"
    },
    href: {
      type: String,
      default: ""
    },
    isSearchAddToCart: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    showIcon(props) {
      return props.icon !== "";
    },
    showIconToolTip(props) {
      return props.iconToolTipAmount !== -1 && props.iconToolTipAmount !== 0;
    },
    isSmall(props) {
      return props.size === 'sm';
    }
  },
  methods: {
    click() {
      if (this.disabled) {
        return;
      }
      if (this.href !== "" && this.href !== null) {
        window.location.href = this.href;
        return;
      }
      this.$emit("click");
    }
  }
};
