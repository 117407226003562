export const state = () => ({
    routeData: null,
})

export const getters = {
    getRouteData(state) {
        return state.routeData;
    },
    getRouteDataType(state) {
        return state.routeData?.type;
    },
    getRouteDataUid(state) {
        return state.routeData?.uid;
    },
}

export const mutations = {
    setRouteData(state, routeData) {
        state.routeData = routeData;
    },
}
