import { Logger } from "~/helpers/logger";

/**
 * Receives an error and the Nuxt context error function, and if it determines we received a 500+ error code, we show
 * the 503 maintenance page.
 *
 * @param error
 * @param nuxtError
 */
export function handleMaintenancePageError(error, nuxtError) {
  if (error?.isAxiosError && error?.response?.data?.networkError?.statusCode >= 500) {
    console.error(`[ERROR] Received error code ${error?.response?.data?.networkError?.statusCode}`, error?.response?.data.networkError);
    nuxtError({ statusCode: 503, message: "Maintenance mode" });
  }
}
