import {ComposableFunctionArgs} from '~/composables/types';
import {Logger} from '~/helpers/logger';
import {Cart} from '~/modules/GraphQL/types';
import {VsfContext} from '~/composables/context';
import loadCartGql from '~/bold/stores/graphql/loadCart.gql';
import guestCartQuery from '~/bold/stores/graphql/guestCartLoad.gql';
import {updateItemQtyCommand} from '~/modules/checkout/composables/useCart/commands/updateItemQtyCommand';
import { removeItemCommand } from '~/modules/checkout/composables/useCart/commands/removeItemCommand';

export const loadCartCommand = {
  execute: async (context: VsfContext, params: ComposableFunctionArgs<{
    realCart?: boolean;
  }>, app, sendNotification) => {
      const apiState = context.$magento.config.state;
    Logger.debug('[Magento Storefront]: Loading Cart');
    const customerToken = apiState.getCustomerToken();
    const virtual = !params.realCart;
    const createVirtualCart = () => (null as Cart);

    const createRealCart = async (): Promise<string> => {
      Logger.debug('[Magento Storefront]: useCart.load.createNewCart');

      apiState.setCartId();

      const { data } = await context.$magento.api.createEmptyCart();
      Logger.debug('[Result]:', { data });

      apiState.setCartId(data.createEmptyCart);

      return data.createEmptyCart;
    };

    const getCartData = async (id: string) => {
      Logger.debug('[Magento Storefront]: useCart.load.getCartData ID->', id);

      const { data, errors } = await context.$magento.api.customQuery({
        query: guestCartQuery,
        queryVariables: {
          cartId:  id
        }
      });

      Logger.debug('[Result]:', { data });

      // @ts-ignore
      if (!data?.cart && errors?.length) {
        throw errors[0];
      }

      // @ts-ignore
      data.cart.items = data.cart.items.filter(Boolean);

      // set stock notification on cart items
      // @ts-ignore
      data.cart = await setStockNotifications(data.cart);

      // @ts-ignore
      return data.cart as unknown as Cart;
    };

    const getCart = async (virtualCart: boolean, cartId?: string) => {
      if (!cartId) {
        if (virtualCart) {
          return createVirtualCart();
        }

        // eslint-disable-next-line no-param-reassign
        cartId = await createRealCart();
        apiState.setCartId(cartId);
      }

      return getCartData(cartId);
    };

    // Try to load cart for existing customer, clean customer token if not possible
    if (customerToken) {
      try {
        const { data, errors } = await context.$magento.api.customQuery({ query: loadCartGql });
        Logger.debug('[Result]:', { data, errors });

        // @ts-ignore
        if (errors?.length) {
          throw errors[0];
        }

        // @ts-ignore
        apiState.setCartId(data.customerCart.id);
        // @ts-ignore
        data.customerCart.items = data.customerCart.items.filter(Boolean);

        // @ts-ignore
        data.customerCart = await setStockNotifications(data.customerCart);

        // @ts-ignore
        return data.customerCart;
      } catch (err) {
        console.error('loadCartCommand execute', err);
        sendNotification({
          icon: 'danger',
          id: Symbol(`load_cart_error`),
          message: app.i18n.t('Failed to load cart.') as string,
          persist: true,
          title: 'Failed to load cart',
          type: 'danger',
        });
        throw err;
      }
    }

    try {
      // If it's not existing customer check if cart id is set and try to load it
      const cartId = apiState.getCartId();
      return await getCart(virtual, cartId);
    } catch {
      apiState.setCartId();
      return await getCart(virtual);
    }
    // Set stock notification if there are items in wms out of stock. Then set available qty of product.
    // Message will be rendered on component and not added here due to translations error.
    // Also update qty of product to available qty, if available qty is 0 then remove item.
    async function setStockNotifications(cart) {
        let matchedSkus = [];

        // Map wms stock error messages to cart items to display stock messages
        // @ts-ignore
        if (cart && cart.wms_out_of_stock_items.items) {
            // @ts-ignore
            for (const item of cart.wms_out_of_stock_items.items) {
                // @ts-ignore
                for (const product of cart.items) {
                    if (product.product.sku === item.sku) {
                        if (item.available_qty > 0) {
                            // update cartitem  to available qty
                            cart = await updateItemQtyCommand.execute(context, {
                                product: product,
                                quantity: item.available_qty,
                                currentCart: cart,
                            });

                            sendNotification({
                                icon: 'danger',
                                id: Symbol(`product_out_of_stock`),
                                message: app.i18n.t('Per il prodotto ' +  product.product.name + '  con SKU ' + product.product.sku + ' abbiamo aggiornato la quantità da te inserita nel carrello secondo la disponibilità degli articoli in giacenza presso i nostri magazzini (' + item.available_qty + ').') as string,
                                persist: true,
                                title: 'Product out of stock',
                                type: 'danger',
                            });
                        } else {
                            // When product has no availability then remove item from cart
                            cart = await removeItemCommand.execute(context, {
                                currentCart: cart,
                                product,
                                customQuery: null
                            });

                            sendNotification({
                                icon: 'danger',
                                id: Symbol(`product_out_of_stock`),
                                message: app.i18n.t('Il prodotto ' + product.product.name + ' con SKU ' + product.product.sku + ' è esaurito, pertanto lo abbiamo rimosso dal carrello.') as string,
                                persist: true,
                                title: 'Product out of stock',
                                type: 'danger',
                            });
                        }

                        matchedSkus.push(product.sku);
                    } else if (!matchedSkus.includes(product.sku)) {
                        product.available_qty = null;
                    }
                }
            }
        }

        return cart;
    }
  }
};
