import { Middleware } from '@nuxt/types';
import { Logger } from '~/helpers/logger';
import { handleMaintenancePageError } from '~/helpers/maintenancePageErrorHandler';
import { RoutableInterface } from '~/modules/GraphQL/types';

const urlResolverMiddleware : Middleware = async (context) => {
  const { path } = context.route;

  const clearUrl = path.replace(/[a-z]+\/[cp|]\//gi, '').replace(`/${context.i18n.locale}`, '');

  Logger.debug('middleware/url-resolver', clearUrl);

  try {
    const {data, errors} = await context.app.$vsf.$magento.api.route(clearUrl);

    Logger.debug('middleware/url-resolver/result', {data, errors});

    const results: RoutableInterface | null = data?.boldRoute ?? null;

    if (!results || errors?.length) {
      // Normally a 404 would be shown here, but we first want to check if the URL isn't a storyblok page
      // If it's not a storyblok page either, that component will show a 404
      context.store.commit('page/setRouteData', {type: "STORYBLOK_PAGE", uid: clearUrl});

      return;
    }

    context.store.commit('page/setRouteData', results);
  } catch (err) {
    handleMaintenancePageError(err, context.error);
  }
};

export default urlResolverMiddleware;