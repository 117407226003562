import { Logger } from '~/helpers/logger';
import { Cart } from '~/modules/GraphQL/types';
import { VsfContext } from '~/composables/context';
import applyCouponToCartGql from '~/bold/stores/graphql/applyCouponToCart.gql';

export const applyCouponCommand = {
  execute: async (context: VsfContext, {
    currentCart,
    couponCode,
    customQuery = { applyCouponToCart: 'applyCouponToCart' },
  }) => {
    Logger.debug('[Magento]: Apply coupon on cart', {
      couponCode,
      currentCart,
    });

    const {data, errors} = await context.$magento.api.customQuery({
      query: applyCouponToCartGql,
      queryVariables: {
        input: {
          cart_id: currentCart.id,
          coupon_code: couponCode
        }
      },
    }, customQuery);



    Logger.debug('[Result]:', { data, errors });

    // @ts-ignore
    return {updatedCart: data.applyCouponToCart?.cart as unknown as Cart, updatedCoupon: { code: couponCode }, errors,
    };
  },
};
