import { computed, reactive } from '@nuxtjs/composition-api';
import { StateInterface, UseUiStateInterface } from '~/composables/useUiState/useUiState';
import type { Product } from '~/modules/catalog/product/types';

const state = reactive<StateInterface>({
  isCartSidebarOpen: false,
  isWishlistSidebarOpen: false,
  isLoginModalOpen: false,
  isNewsletterModalOpen: false,
  isCategoryGridView: true,
  isWishlistGridView: true,
  isFilterSidebarOpen: false,
  isMobileMenuOpen: false,
  isAddressValidationModalOpen: false,
  shouldGoToBillingStep: false,
  isAddToCartModalOpen: false,
  addToCartActiveProduct: null,
});

/**
 * Global store for managing UI state.
 *
 * See the {@link UseUiStateInterface} for a list of methods and values available in this composable.
 */
export function useUiState(): UseUiStateInterface {
  const toggleMobileMenu = () => {
    state.isMobileMenuOpen = !state.isMobileMenuOpen;
  };

  const toggleCartSidebar = () => {
    if (state.isMobileMenuOpen) toggleMobileMenu();
    state.isCartSidebarOpen = !state.isCartSidebarOpen;
  };

  const toggleWishlistSidebar = () => {
    if (state.isMobileMenuOpen) toggleMobileMenu();
    state.isWishlistSidebarOpen = !state.isWishlistSidebarOpen;
  };

  const toggleLoginModal = () => {
    if (state.isMobileMenuOpen) toggleMobileMenu();
    state.isLoginModalOpen = !state.isLoginModalOpen;
  };

  const toggleNewsletterModal = () => {
    state.isNewsletterModalOpen = !state.isNewsletterModalOpen;
  };

  const changeToCategoryGridView = () => {
    state.isCategoryGridView = true;
  };
  const changeToCategoryListView = () => {
    state.isCategoryGridView = false;
  };

  const changeToWishlistGridView = () => {
    state.isWishlistGridView = true;
  };

  const changeToWishlistListView = () => {
    state.isWishlistGridView = false;
  };
  const toggleFilterSidebar = () => {
    state.isFilterSidebarOpen = !state.isFilterSidebarOpen;
  };

  const toggleAddressValidationModal = () => {
    state.isAddressValidationModalOpen = !state.isAddressValidationModalOpen;
  };

  const setGoToBillingStep = (userAction: boolean) => {
    state.shouldGoToBillingStep = userAction;
  };

  const toggleAddToCartModal = () => {
    state.isAddToCartModalOpen = !state.isAddToCartModalOpen;
  };

  const closeAddToCartModal = () => {
    state.isAddToCartModalOpen = false;
  }

  const setAddToCartActiveProduct = (product: Product) => {
    state.addToCartActiveProduct = product;
  }

  return {
    isMobileMenuOpen: computed(() => state.isMobileMenuOpen),
    isCartSidebarOpen: computed(() => state.isCartSidebarOpen),
    isWishlistSidebarOpen: computed(() => state.isWishlistSidebarOpen),
    isLoginModalOpen: computed(() => state.isLoginModalOpen),
    isNewsletterModalOpen: computed(() => state.isNewsletterModalOpen),
    isCategoryGridView: computed(() => state.isCategoryGridView),
    isWishlistGridView: computed(() => state.isWishlistGridView),
    isFilterSidebarOpen: computed(() => state.isFilterSidebarOpen),
    isAddressValidationModalOpen: computed(() => state.isAddressValidationModalOpen),
    shouldGoToBillingStep: computed(() => state.shouldGoToBillingStep),
    isAddToCartModalOpen: computed(() => state.isAddToCartModalOpen),
    addToCartActiveProduct: computed(() => state.addToCartActiveProduct),
    toggleMobileMenu,
    toggleCartSidebar,
    toggleWishlistSidebar,
    toggleLoginModal,
    toggleNewsletterModal,
    changeToCategoryGridView,
    changeToCategoryListView,
    changeToWishlistGridView,
    changeToWishlistListView,
    toggleFilterSidebar,
    toggleAddressValidationModal,
    setGoToBillingStep,
    toggleAddToCartModal,
    closeAddToCartModal,
    setAddToCartActiveProduct
  };
}

export default useUiState;
export * from './useUiState';
