import { extend } from 'vee-validate';

extend('codice_fiscale', {
    message: () => 'The fiscal code is invalid',
    validate: value => {
        /**
         * Short explanation
         *
         * 1-3: surname, 3 letters
         * 4-6: name, 3 letters
         * 7-8: year of date of birth, 2 digits
         * 9: month of date of birth, 1 letter
         * 10-11: day of date of birth and sex, 2 digits
         * 12-15 common (or state) of birth, 4 letters/digits
         *      Always starts with 1 letter
         *      Almost always ends with 3 digits. But the last (and?/)or second to last could be a letter in rare cases
         * 16: control character, 1 letter
         *
         * @type {RegExp}
         */
        const regex = /^[A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{1}[0-9A-Za-z]{2}[A-Za-z]{1}$/;
        const codiceFiscale = value.toUpperCase().trim().replaceAll(' ', '');

        if (!regex.test(codiceFiscale)) {
            return false;
        }
        const oddMap = {
            '0': 1,
            '1': 0,
            '2': 5,
            '3': 7,
            '4': 9,
            '5': 13,
            '6': 15,
            '7': 17,
            '8': 19,
            '9': 21,
            'A': 1,
            'B': 0,
            'C': 5,
            'D': 7,
            'E': 9,
            'F': 13,
            'G': 15,
            'H': 17,
            'I': 19,
            'J': 21,
            'K': 2,
            'L': 4,
            'M': 18,
            'N': 20,
            'O': 11,
            'P': 3,
            'Q': 6,
            'R': 8,
            'S': 12,
            'T': 14,
            'U': 16,
            'V': 10,
            'W': 22,
            'X': 25,
            'Y': 24,
            'Z': 23
        };

        const evenMap = {
            '0': 0,
            '1': 1,
            '2': 2,
            '3': 3,
            '4': 4,
            '5': 5,
            '6': 6,
            '7': 7,
            '8': 8,
            '9': 9,
            'A': 0,
            'B': 1,
            'C': 2,
            'D': 3,
            'E': 4,
            'F': 5,
            'G': 6,
            'H': 7,
            'I': 8,
            'J': 9,
            'K': 10,
            'L': 11,
            'M': 12,
            'N': 13,
            'O': 14,
            'P': 15,
            'Q': 16,
            'R': 17,
            'S': 18,
            'T': 19,
            'U': 20,
            'V': 21,
            'W': 22,
            'X': 23,
            'Y': 24,
            'Z': 25,
        };

        let total = 0;
        for (let i = 0; i < 15; i += 2) {
            total += oddMap[codiceFiscale[i]];
        }

        for (let i = 1; i < 15; i += 2){
            total += evenMap[codiceFiscale[i]];
        }

        return codiceFiscale.charCodeAt(15) === ('A'.charCodeAt(0) + total % 26);
    }
});

extend('telephone', {
    message: () => 'The telephone number is invalid',
    validate: value => {
        const regex = /^(\+?[0-9 ]{6,32})$/;
        return regex.test(value);
    }
});