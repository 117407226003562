export default `
  query categorySearch($term: String) {
    categorySearch(search: $term) {
        uid,
        name,
        url_key,
        url_path,
        canonical_url
    }
  }
`;
