import type { Plugin } from '@nuxt/types';
import type { ApolloQueryResult } from '@apollo/client/core/types';

export const hasGraphqlAuthorizationError = (res: ApolloQueryResult<unknown>) => res?.errors
  ?.some((error) => error?.extensions?.category === 'graphql-authorization') ?? false;

const plugin : Plugin = ({ app, store }) => {
  if (app.$vsf.$magento.config.state.getCustomerToken()) {
    store.commit('customer/setIsLoggedIn', true);
  }

  app.$vsf.$magento.client.interceptors.response.use((res) => {
    if (!hasGraphqlAuthorizationError(res.data as ApolloQueryResult<unknown>)) {
      return res;
    }
    store.commit('customer/setIsLoggedIn', false);
    app.$vsf.$magento.config.state.removeCustomerToken();
    app.$vsf.$magento.config.state.removeCartId();

    app.router.push(app.localePath('/'));

    return false;
  });
};

export default plugin;
