import { UseContextReturn } from '~/types/core';
import type { PlaceOrderResponse } from '~/modules/GraphQL/types';
import { ComposableFunctionArgs } from '~/composables';
import placeOrderQuery from '~/bold/stores/graphql/placeOrder.gql';

export const placeOrderCommand = {
  execute: async (context: UseContextReturn, cartId: string, params?: ComposableFunctionArgs<{}>): Promise<PlaceOrderResponse | null> => {
    const { data, errors } = await context.app.$vsf.$magento.api.customQuery({
      // @ts-ignore
      query: placeOrderQuery,
      queryVariables: {
        input: {
          cart_id: cartId,
        },
      },
    });

    return {
      // @ts-ignore
      order: data?.placeOrder ?? null,
      error: errors?.[0].message ?? null
    }
  },
};
