export default `
 query getOrderWithProtectCode($incrementId: String!, $protectCode: String!) {
  getOrderWithProtectCode(incrementId: $incrementId, protectCode: $protectCode) {
    id
    number
    shipping_costs
    tax
    applied_coupon
    shipping_method_code
    region_code
    postcode
    telephone
    email
    country
    city
    address
    is_new_customer
    billing_address {
        firstname
        lastname
     }
    payment_methods {
      type
    }
    items {
      product_name
      product_sku
      quantity_ordered
      discounts {
        amount {
          value
        }
        label
      }
      product_sale_price {
        currency
        value
      }
      categories {
         uid
         name
         url_suffix
         url_path
         breadcrumbs {
           category_name,
           category_url_path
        }
      }
      mpbrand {
        value
      }
      promotion {
        promotion_name
        promotion_id
        creative_name
        creative_slot
      }
      item_list {
        item_list_name
        item_list_id
      }
    }
    total {
      subtotal {
        value
        currency
      }
      base_grand_total {
        value
      }
      total_tax {
        value
      }
      total_shipping {
        value
      }
    }
  }
}
`;
