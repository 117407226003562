import { integrationPlugin } from '~/helpers/integrationPlugin';
import { mapConfigToSetupObject } from '~/modules/core/helpers';

const moduleOptions = JSON.parse('{}');
/**
 * configure the integration so that it can be called in the frontend from the $vsf context
 */
export default integrationPlugin((plugin) => {
  const settings = mapConfigToSetupObject({
    moduleOptions,
    app: plugin.app
  });

  plugin.integration.configure('tntServices', settings);
});
