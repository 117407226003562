/** GraphQL mutation that places an order, and returns a bit of extra info, like protect_code and increment_id. */
export default `
    mutation placeOrder($input: PlaceOrderInput) {
        placeOrder(input: $input) {
            order {
                order_number,
                bold_order_details {
                    protect_code,
                    increment_id
                }
            }
        }
    }
`;
