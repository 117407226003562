import { Logger } from '~/helpers/logger';
import { Cart } from '~/modules/GraphQL/types';
import { VsfContext } from '~/composables/context';
import removeCouponFromCartGql from '~/bold/stores/graphql/removeCouponFromCart.gql';


export const removeCouponCommand = {
  execute: async (context: VsfContext, { currentCart, customQuery = { removeCouponFromCart: 'removeCouponFromCart' } }) => {
    Logger.debug('[Magento]: Remove coupon from cart', { currentCart });

    const { data, errors } = await context.$magento.api.customQuery({
      query: removeCouponFromCartGql,
      queryVariables: {input : {cart_id: currentCart.id}},
    }, customQuery);


    Logger.debug('[Result]:', { data });

    // @ts-ignore
    return {updatedCart: data.removeCouponFromCart?.cart as unknown as Cart, updatedCoupon: { code: '' }, errors,
    };
  },
};
