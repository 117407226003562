//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {SfBottomNavigation, SfCircleIcon, SfImage} from '@storefront-ui/vue';
import {computed, defineComponent, useContext, useRouter, useRoute, onMounted} from '@nuxtjs/composition-api';
import {useUiState} from '~/composables';
import {useUser} from '~/modules/customer/composables/useUser';
import SvgImage from '~/components/General/SvgImage.vue';
import { useCategoryStore } from '~/modules/catalog/category/stores/category';
import { addBasePath } from '~/helpers/addBasePath';
import cartGetters from '~/modules/checkout/getters/cartGetters';
import {useCart} from '~/modules/checkout/composables/useCart';
import { useSearchBar } from "~/composables/header/searchBar/searchBar";
import { useNoScroll } from '~/composables/useNoScroll';

export default defineComponent({
  components: {
    SfBottomNavigation,
    SfCircleIcon,
    SvgImage,
    SfImage
  },
  setup() {
    const {
      toggleCartSidebar,
      toggleWishlistSidebar,
      toggleLoginModal,
      toggleMobileMenu,
      isMobileMenuOpen,
    } = useUiState();
    const {isAuthenticated} = useUser();
    const {cart, loadTotalQty} = useCart();
    const totalItems = computed(() => cartGetters.getTotalItems(cart.value));
    const router = useRouter();
    const route = useRoute();
    const {app} = useContext();
    const { toggleSearch, isSearchOpen } = useSearchBar();
    const { enableScrolling } = useNoScroll();

    const handleAccountClick = async () => {
      if (isAuthenticated.value) {
        await router.push(`${app.localePath('/customer')}`);
      } else {
        toggleLoginModal();
      }
    };

    const homeClick = async () => {
      const homePath = app.localeRoute({ name: 'home' });
      await router.push(homePath);
      if (document) {
        enableScrolling();
      }
      if (isMobileMenuOpen.value) toggleMobileMenu();
    }

    const searchClick = async () => {
      if (isMobileMenuOpen.value) toggleMobileMenu();
      toggleSearch();
    }

    const loadCategoryMenu = async () => {
      const categories = useCategoryStore();
      if (categories.categories === null) {
        await categories.load();
      }
      toggleMobileMenu();
    };

    onMounted(async () => {
      await loadTotalQty();
    });

    const isCustomerRoute = computed(() => !!(route.value.name && route.value.name.startsWith('customer')));

    return {
      isAuthenticated,
      isMobileMenuOpen,
      toggleWishlistSidebar,
      toggleCartSidebar,
      toggleMobileMenu,
      loadCategoryMenu,
      handleAccountClick,
      app,
      addBasePath,
      toggleSearch,
      totalItems,
      homeClick,
      searchClick,
      isCustomerRoute,
      isSearchOpen,
    };
  },
});
