const formatCurrency = (value: number | string, locale: string, options: Intl.NumberFormatOptions): string => {
    if (typeof value === 'string') {
        // eslint-disable-next-line no-param-reassign
        value = Number(value);
    }
    //bold edit - hardcode locale to en-US for currencies so that the currency symbol appears in front and is consistent
    // eslint-disable-next-line no-param-reassign
    return new Intl.NumberFormat('en-US', { style: 'currency', ...options }).format(value);
};

export default formatCurrency;
