import { Customer } from "~/modules/GraphQL/types";

interface CustomerState {
    user: Customer | null,
        isLoggedIn: boolean,
}

export const state = () => ({
    user: null,
    isLoggedIn: false,
})

export const getters = {
    getUser(state) {
        return state.user;
    },
    getIsLoggedIn(state) {
        return state.isLoggedIn;
    },
}

export const mutations = {
    setUser(state, user: Customer | null) {
        state.user = user;
    },
    setIsLoggedIn(state, isLoggedIn: boolean) {
        state.isLoggedIn = isLoggedIn;
    },
}
