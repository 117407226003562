import { readonly, ref, useContext } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import { getProductDetailsCommand } from '~/modules/catalog/product/composables/useProduct/commands/getProductDetailsCommand';
import type { GetProductSearchParams } from '~/modules/catalog/product/types';
import type { ProductInterface } from '~/modules/GraphQL/types';
import type {
  ProductDetails,
  ProductList,
  UseProductErrors,
  UseProductInterface,
} from './useProduct';
import productListGql from '~/bold/stores/graphql/productList.gql';
import getUpsellProductsGql from '~/bold/stores/graphql/getUpsellProducts.gql';
import getCrossSellProductsGql from '~/bold/stores/graphql/getCrossSellProducts.gql';

/**
 * Allows loading product details or list with
 * params for sorting, filtering and pagination.
 *
 * See the {@link UseProductInterface} for a list of methods and values available in this composable.
 */
export function useProduct(id?: string): UseProductInterface {
  const loading = ref(false);
  const error = ref<UseProductErrors>({
    getProductList: null,
    getProductDetails: null,
  });

  const context = useContext();

  const { app } = useContext();
  const vsfContext = app.$vsf;

  const getProductList = async (searchParams: GetProductSearchParams): Promise<ProductList | null> => {
    Logger.debug(`useProduct/${id}/getProductList`, searchParams);
    let products: ProductList = null;

    try {
      loading.value = true;

      const {data}  = await vsfContext.$magento.api.customQuery({
        query: productListGql,
        queryVariables: searchParams,
      });

      // @ts-ignore
      products = data?.products;

      error.value.getProductList = null;
    } catch (err) {
      error.value.getProductList = err;
      console.error(`useProduct/${id}/search`, err);
    } finally {
      loading.value = false;
    }

    return products;
  };

  const getProductDetails = async (searchParams: GetProductSearchParams): Promise<ProductDetails | null> => {
    Logger.debug(`useProduct/${id}/getProductDetails`, searchParams);
    let products: ProductDetails = null;

    try {
      loading.value = true;

      products = await getProductDetailsCommand.execute(context, searchParams);

      error.value.getProductDetails = null;
    } catch (err) {
      error.value.getProductDetails = err;
      console.error(`useProduct/${id}/search`, err);
    } finally {
      loading.value = false;
    }

    return products;
  };

  /**
   * Get upsell products from custom graphQL query that gets best sold items from day before, excluding current product
   *
   * @param productId
   */
  const getUpsellProducts = async (productId: number): Promise<any> => {
    try {
      loading.value = true;

      const {data, errors} = await app.$vsf.$magento.api.customQuery({
        // @ts-ignore
        query: getUpsellProductsGql,
        queryVariables: {
          input: {
            productId: productId,
          }
        }
      });

      error.value = null;

      return {data, errors};
    } catch (err) {
      error.value = err;
      console.error(`useProduct/${id}/search`, err);
    } finally {
      loading.value = false;
    }
  };


  /**
   * Get cross-sell products from custom graphQL query that gets best sold items from day before.
   * Excluding current product
   *
   * @param productId
   */
  const getCrossSellProducts = async (productId: number): Promise<any> => {
    try {
      loading.value = true;

      const {data, errors} = await app.$vsf.$magento.api.customQuery({
        // @ts-ignore
        query: getCrossSellProductsGql,
        queryVariables: {
          input: {
            productId: productId,
          }
        }
      });

      error.value = null;

      return {data, errors};
    } catch (err) {
      error.value = err;
      console.error(`useProduct/${id}/search`, err);
    } finally {
      loading.value = false;
    }
  };

  const getProductPath = (product: ProductInterface) => {
    if (!product) return '/';
    return `/${product?.url_rewrites?.[0]?.url ?? product.url_key}`;
  };

  return {
    getProductList,
    getProductDetails,
    getProductPath,
    getUpsellProducts,
    getCrossSellProducts,
    error: readonly(error),
    loading: readonly(loading),
  };
}

export * from './useProduct';
export default useProduct;
