import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5f4f32c4 = () => interopDefault(import('../pages/BrandDetail.vue' /* webpackChunkName: "pages/BrandDetail" */))
const _31487856 = () => interopDefault(import('../pages/Brands.vue' /* webpackChunkName: "pages/Brands" */))
const _980f2b9c = () => interopDefault(import('../pages/Search.vue' /* webpackChunkName: "" */))
const _a301d77a = () => interopDefault(import('../modules/checkout/pages/Checkout.vue' /* webpackChunkName: "" */))
const _deb8aae2 = () => interopDefault(import('../modules/checkout/pages/Checkout/Billing.vue' /* webpackChunkName: "" */))
const _2f52fc9a = () => interopDefault(import('../modules/checkout/pages/Checkout/Payment.vue' /* webpackChunkName: "" */))
const _78e8446c = () => interopDefault(import('../modules/checkout/pages/Checkout/Shipping.vue' /* webpackChunkName: "" */))
const _a5ccad1e = () => interopDefault(import('../modules/checkout/pages/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _1d6e0a76 = () => interopDefault(import('../modules/checkout/pages/Checkout/UserAccount.vue' /* webpackChunkName: "" */))
const _740316af = () => interopDefault(import('../pages/Cms.vue' /* webpackChunkName: "pages/Cms" */))
const _2e51f63e = () => interopDefault(import('../modules/customer/pages/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _4b19c3ac = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressesDetails.vue' /* webpackChunkName: "" */))
const _236eec38 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyNewsletter.vue' /* webpackChunkName: "" */))
const _6e20a5b0 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyProfile/MyProfile.vue' /* webpackChunkName: "" */))
const _4ccbb92c = () => interopDefault(import('../modules/customer/pages/MyAccount/MyReviews.vue' /* webpackChunkName: "" */))
const _4be06504 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyWishlist.vue' /* webpackChunkName: "" */))
const _6c8eb428 = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _0fa47167 = () => interopDefault(import('../modules/customer/pages/MyAccount/ResetPassword.vue' /* webpackChunkName: "" */))
const _ea4d8d10 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressNew.vue' /* webpackChunkName: "" */))
const _ca0c4406 = () => interopDefault(import('../modules/customer/pages/MyAccount/Booking/Appointments.vue' /* webpackChunkName: "" */))
const _6b0a2215 = () => interopDefault(import('../modules/customer/pages/MyAccount/Booking/Appointment/Create.vue' /* webpackChunkName: "" */))
const _5df295a2 = () => interopDefault(import('../modules/customer/pages/MyAccount/CartaUnica/ActivateCard.vue' /* webpackChunkName: "" */))
const _5404373a = () => interopDefault(import('../modules/customer/pages/MyAccount/Booking/Appointment/Edit.vue' /* webpackChunkName: "" */))
const _84702904 = () => interopDefault(import('../modules/customer/pages/MyAccount/Booking/Appointment/View.vue' /* webpackChunkName: "" */))
const _3f59f33c = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressEdit.vue' /* webpackChunkName: "" */))
const _35382187 = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _763b5a69 = () => interopDefault(import('../pages/Home.vue' /* webpackChunkName: "pages/Home" */))
const _731f1fce = () => interopDefault(import('../pages/Page.vue' /* webpackChunkName: "pages/Page" */))
const _07230a74 = () => interopDefault(import('../modules/booking/pages/appointment/Create.vue' /* webpackChunkName: "" */))
const _301d5ab0 = () => interopDefault(import('../pages/StoryblokPage.vue' /* webpackChunkName: "pages/StoryblokPage" */))
const _57f7f456 = () => interopDefault(import('../pages/StyleGuide.vue' /* webpackChunkName: "pages/StyleGuide" */))
const _e38cece6 = () => interopDefault(import('../pages/Customer/AbandonedCart.vue' /* webpackChunkName: "pages/Customer/AbandonedCart" */))
const _4621da80 = () => interopDefault(import('../pages/styleguide/Icons.vue' /* webpackChunkName: "pages/styleguide/Icons" */))
const _1533b001 = () => interopDefault(import('../pages/Customer/Account/ResetPassword.vue' /* webpackChunkName: "pages/Customer/Account/ResetPassword" */))
const _79ee4215 = () => interopDefault(import('../pages/Newsletter/Subscriber/Confirm.vue' /* webpackChunkName: "pages/Newsletter/Subscriber/Confirm" */))
const _a52540c6 = () => interopDefault(import('../modules/booking/pages/appointment/View.vue' /* webpackChunkName: "" */))
const _2efaf336 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/BrandDetail",
    component: _5f4f32c4,
    name: "BrandDetail___default"
  }, {
    path: "/Brands",
    component: _31487856,
    name: "Brands___default"
  }, {
    path: "/cerca",
    component: _980f2b9c,
    name: "cerca___default"
  }, {
    path: "/checkout",
    component: _a301d77a,
    meta: {"hideSearchOnMobile":true},
    name: "checkout___default",
    children: [{
      path: "billing",
      component: _deb8aae2,
      meta: {"hideSearchOnMobile":true},
      name: "billing___default"
    }, {
      path: "payment",
      component: _2f52fc9a,
      meta: {"hideSearchOnMobile":true},
      name: "payment___default"
    }, {
      path: "shipping",
      component: _78e8446c,
      meta: {"hideSearchOnMobile":true},
      name: "shipping___default"
    }, {
      path: "thank-you",
      component: _a5ccad1e,
      meta: {"hideSearchOnMobile":true},
      name: "thank-you___default"
    }, {
      path: "user-account",
      component: _1d6e0a76,
      meta: {"hideSearchOnMobile":true},
      name: "user-account___default"
    }]
  }, {
    path: "/Cms",
    component: _740316af,
    name: "Cms___default"
  }, {
    path: "/customer",
    component: _2e51f63e,
    meta: {"titleLabel":"My Account"},
    name: "customer___default",
    children: [{
      path: "addresses-details",
      component: _4b19c3ac,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___default"
    }, {
      path: "my-newsletter",
      component: _236eec38,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___default"
    }, {
      path: "my-profile",
      component: _6e20a5b0,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___default"
    }, {
      path: "my-reviews",
      component: _4ccbb92c,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___default"
    }, {
      path: "my-wishlist",
      component: _4be06504,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___default"
    }, {
      path: "order-history",
      component: _6c8eb428,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___default"
    }, {
      path: "/reset-password",
      component: _0fa47167,
      alias: "/customer/account/createPassword",
      name: "reset-password___default"
    }, {
      path: "addresses-details/create",
      component: _ea4d8d10,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___default"
    }, {
      path: "/customer/appointments",
      component: _ca0c4406,
      name: "customer-my-appointments___default"
    }, {
      path: "/customer/booking/create",
      component: _6b0a2215,
      name: "customer-create-appointment___default"
    }, {
      path: "/customer/carta-unica/activateCard",
      component: _5df295a2,
      meta: {"titleLabel":"Activate Carta Unica"},
      name: "customer-carta-unica___default"
    }, {
      path: "/customer/booking/edit/:id",
      component: _5404373a,
      props: true,
      name: "customer-edit-appointment___default"
    }, {
      path: "/customer/booking/view/:id",
      component: _84702904,
      props: true,
      name: "customer-view-appointment___default"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _3f59f33c,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___default"
    }, {
      path: "order-history/:orderId",
      component: _35382187,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___default"
    }]
  }, {
    path: "/Home",
    component: _763b5a69,
    name: "Home___default"
  }, {
    path: "/Page",
    component: _731f1fce,
    name: "Page___default"
  }, {
    path: "/Search",
    component: _980f2b9c,
    name: "Search___default"
  }, {
    path: "/servizi",
    component: _07230a74,
    name: "booking-create-appointment___default"
  }, {
    path: "/StoryblokPage",
    component: _301d5ab0,
    name: "StoryblokPage___default"
  }, {
    path: "/StyleGuide",
    component: _57f7f456,
    name: "StyleGuide___default"
  }, {
    path: "/Customer/AbandonedCart",
    component: _e38cece6,
    name: "Customer-AbandonedCart___default"
  }, {
    path: "/styleguide/Icons",
    component: _4621da80,
    name: "styleguide-Icons___default"
  }, {
    path: "/Customer/Account/ResetPassword",
    component: _1533b001,
    name: "Customer-Account-ResetPassword___default"
  }, {
    path: "/Newsletter/Subscriber/Confirm",
    component: _79ee4215,
    name: "Newsletter-Subscriber-Confirm___default"
  }, {
    path: "/servizi/view/:id",
    component: _a52540c6,
    props: true,
    name: "view-appointment___default"
  }, {
    path: "/brands/:brand",
    component: _5f4f32c4,
    name: "brand___default"
  }, {
    path: "/",
    component: _763b5a69,
    name: "home___default"
  }, {
    path: "/*",
    component: _2efaf336,
    name: "all___default"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
