//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from "vue";
import SfHeaderNavigationItem from "@storefront-ui/vue/src/components/organisms/SfHeader/_internal/SfHeaderNavigationItem.vue";
import SfHeaderNavigation from "@storefront-ui/vue/src/components/organisms/SfHeader/_internal/SfHeaderNavigation.vue";
Vue.component("SfHeaderNavigation", SfHeaderNavigation);
Vue.component("SfHeaderNavigationItem", SfHeaderNavigationItem);
import LazyHydrate from 'vue-lazy-hydration';
import { isClient } from "@storefront-ui/vue/src/utilities/helpers";
import SfImage from "@storefront-ui/vue/src/components/atoms/SfImage/SfImage.vue";
import SfSearchBar from "@storefront-ui/vue/src/components/molecules/SfSearchBar/SfSearchBar.vue";
import SfButton from "@storefront-ui/vue/src/components/atoms/SfButton/SfButton.vue";
import SfIcon from "@storefront-ui/vue/src/components/atoms/SfIcon/SfIcon.vue";
import SfLink from "@storefront-ui/vue/src/components/atoms/SfLink/SfLink.vue";
import {useStoryblok} from "@storyblok/nuxt-2";
import {useContext} from "@nuxtjs/composition-api";
import { useGetCacheVersion } from '~/modules/storyblok/composables/getCacheVersion';
import SkeletonLoader from '~/components/SkeletonLoader/index.vue';

export default {
  name: "BoldHeader",
  components: {
    SfImage,
    SfSearchBar,
    SfButton,
    SfIcon,
    SfLink,
    LazyHydrate,
    SkeletonLoader
  },
  props: {
    logo: {
      type: [String, Object],
      default: "",
    },
    logoHeight: {
      type: [Number, String],
      default: 35,
    },
    logoWidth: {
      type: [Number, String],
      default: 34,
    },
    title: {
      type: String,
      default: "",
    },
    cartIcon: {
      type: [String, Boolean, Array],
      default: "empty_cart",
    },
    wishlistIcon: {
      type: [String, Boolean, Array],
      default: "heart",
    },
    accountIcon: {
      type: [String, Boolean, Array],
      default: "profile",
    },
    activeIcon: {
      type: String,
      default: "",
      validator(value) {
        return ["", "account", "wishlist", "cart"].includes(value);
      },
    },
    searchPlaceholder: {
      type: String,
      default: "",
    },
    searchValue: {
      type: String,
      default: "",
    },
    wishlistItemsQty: {
      type: [String, Number],
      default: "0",
    },
    cartItemsQty: {
      type: [String, Number],
      default: "0",
    },
    isSticky: {
      type: Boolean,
      default: false,
    },
    isNavVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      icons: [],
      hidden: false,
      sticky: false,
      scrollDirection: null,
      lastScrollPosition: 0,
      animationStart: null,
      animationLong: null,
      animationDuration: 300,
    };
  },
  setup() {
    let shelveCat = null;
    const { $config } = useContext();
    const { cacheVersion } = useGetCacheVersion();


    if (process.client) {
      shelveCat = useStoryblok("header/shelve-categories", {version: $config.storyblokMode, cv: cacheVersion}).story;
    }

    return {
      shelveCat,
    }
  },
  computed: {
    cartHasProducts() {
      return parseInt(this.cartItemsQty, 10) > 0;
    },
    wishlistHasProducts() {
      return parseInt(this.wishlistItemsQty, 10) > 0;
    },
  },
  watch: {
    scrollDirection: {
      handler() {
        if (!isClient) return;
        window.cancelAnimationFrame(this.animationLong);
        this.animationLong = null;
        this.animationStart = null;
        this.animationLong = window.requestAnimationFrame(
          this.animationHandler
        );
      },
    },
    isSticky: {
      handler(isSticky) {
        if (!isClient) return;
        this.sticky = isSticky;
      },
      immediate: true,
    },
  },
  mounted() {
    if (this.isSticky) {
      window.addEventListener("scroll", this.scrollHandler, { passive: true });
    }
  },
  beforeDestroy() {
    if (this.isSticky) {
      window.removeEventListener("scroll", this.scrollHandler, {
        passive: true,
      });
    }
  },
  methods: {
    animationHandler(timestamp) {
      if (!this.animationStart) this.animationStart = timestamp;
      const progress = timestamp - this.animationStart;
      if (progress < this.animationDuration) {
        this.animationLong = window.requestAnimationFrame(
          this.animationHandler
        );
        return;
      }
      this.hidden = this.scrollDirection === "down";
    },
    scrollHandler() {
      if (!isClient) return;
      const currentScrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      if (!!this.refs) {
        if (currentScrollPosition >= this.$refs.header.offsetHeight) {
          this.scrollDirection =
            currentScrollPosition < this.lastScrollPosition ? "up" : "down";
        }
      }
      this.lastScrollPosition = currentScrollPosition;
    },
  },
};
