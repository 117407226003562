import { Logger } from '~/helpers/logger';
import { Cart } from '~/modules/GraphQL/types';
import { VsfContext } from '~/composables/context';
import updateItemQtyGql from '~/bold/stores/graphql/updateItemQty.gql';


export const updateItemQtyCommand = {
  execute: async (context: VsfContext, {
    currentCart,
    product,
    quantity,
    customQuery = { updateCartItems: 'updateCartItems' },
  }) => {
    Logger.debug('[Magento]: Update product quantity on cart', {
      product,
      quantity,
      currentCart,
    });

    const updateCartParams = {
      cart_id: currentCart.id,
      cart_items: [
        {
          cart_item_uid: product.uid,
          quantity,
        },
      ],
    };

      const {data, errors} = await context.$magento.api.customQuery({
          query: updateItemQtyGql,
          queryVariables: {input : updateCartParams}
      });


    Logger.debug('[Result]:', { data });

    if (errors) {
      throw errors[0].message;
    }

    // @ts-ignore
    if (data?.updateCartItems?.user_errors?.length > 0) {
      return [
        // @ts-ignore
        data?.updateCartItems?.cart as unknown as Cart,
        // @ts-ignore
        (String(data.updateCartItems.user_errors[0].message))
      ];
    }

    // @ts-ignore
    return data?.updateCartItems?.cart as unknown as Cart;
  },
};
