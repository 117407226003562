import {computed, reactive, useRoute, watch} from '@nuxtjs/composition-api';
import {Products} from "~/modules/GraphQL/types";
import {useProduct} from '~/modules/catalog/product/composables/useProduct';
import {searchCategories} from '~/composables/catalogsearch/searchCategories';
import { useNoScroll } from '~/composables/useNoScroll';
import { useGtm } from '~/composables/useGtm';

const searchBarTextPlaceHolderFocused = "";
const searchBarTextPlaceHolderUnfocused = "Cosa stai cercando?";

const state = reactive({
    isSearchOpen: false,
    searchBarTextPlaceHolder: searchBarTextPlaceHolderUnfocused,
    routeChangeWatcherInitialized: false,
    searchResults: null,
    categorySearchResults: null,
    isSearching: false,
    hideSearchOnMobile: false,
    searchTerm: "",
});
export function useSearchBar() {
    const {getProductList} = useProduct();
    const {get: getCategoryList} = searchCategories();
    const { enableScrolling, disableScrolling } = useNoScroll();
    const {sendSearchEvent} = useGtm();

    /**
     * All logic for route logic
     *      ex: on mobile the searchbar is shown on all pages except checkout
     */
    if (!state.routeChangeWatcherInitialized) {
        state.routeChangeWatcherInitialized = true;
        const hideSearchIfNeeded = (route) => {
            // meta.hideSearchOnMobile can be set to true in nuxt routes, e.g. theme/modules/checkout/index.ts
            state.hideSearchOnMobile = !!route?.meta?.hideSearchOnMobile;
        }
        const route = useRoute();
        hideSearchIfNeeded(route.value);

        watch(route, (to) => {
            if (typeof document === 'undefined') return;
            hideSearchIfNeeded(to);
            closeSearch();
        });
    }

    /**
     * Searchbar logic that hides the searchbar when clicked on a non searchbar elem
     */
    const initCloseOutsideEvents = () => {
        if (!window) return;
        window.addEventListener('click', function(e: Event & { target: Element }) {
            if (!state.isSearchOpen) return;
            if (!Boolean(e.target.closest(".no-close-searchbar"))) closeSearch();
        });
    }

    /**
     * Open search logic
     */
    const openSearch = () => {
        state.isSearchOpen = true;
        state.searchBarTextPlaceHolder = searchBarTextPlaceHolderFocused;
        disableScrolling();
    }

    /**
     * Close search logic
     */
    const closeSearch = () => {
        state.isSearchOpen = false;
        state.searchBarTextPlaceHolder = searchBarTextPlaceHolderUnfocused;
        enableScrolling();
    }

    const toggleSearch = () => {
        (state.isSearchOpen) ? closeSearch() : openSearch();
    }

    const setSearchResults = (searchResults) => {
        state.searchResults = searchResults;
    }

    const setCategorySearchResults = (searchResults) => {
        state.categorySearchResults = searchResults;
    }

    const setIsSearching = (isSearching) => {
        state.isSearching = isSearching;
    }

    const setSearchTerm = (searchTerm) => {
        state.searchTerm = searchTerm;
    }

    // @ts-ignore
    const rawSearch = async (searchTerm) => {
        let throwSearchEvent = true;

        // do not throw search event if search term is same as previous one. It prevents from throwing it twice
        // because it gets loaded in the mini search and on the search page
        if (state.searchTerm === searchTerm) {
            throwSearchEvent = false;
        }

        setSearchTerm(searchTerm);
        if (searchTerm.length < 3) return;
        setIsSearching(true);

        // M2-579
        const [productList, categoryList] = await Promise.all([
            getProductList({
                pageSize: 12,
                search: searchTerm,
            }) as unknown as Products,
            getCategoryList({term: searchTerm})
        ]);

        // emit('set-search-results', productList!.items);
        // emit('set-category-search-results', categoryList);
        setIsSearching(false);
        setSearchResults(productList!.items);
        setCategorySearchResults(categoryList);

        if (throwSearchEvent) {
            // send search event to gtm. hasResults need to be string instead of boolean. See docs in ticket #287
            let hasResults = 'true';
            if (productList.items.length === 0) {
                hasResults = 'false';
            }

            sendSearchEvent(searchTerm, hasResults);
        }
    }

    // @ts-ignore
    const refreshSearchResults = async () => {
        if (state.searchTerm && !state.isSearching) {
            await rawSearch(state.searchTerm);
        }
    }

    return {
        isSearchOpen: computed(() => state.isSearchOpen),
        searchBarTextPlaceHolder: computed(() => state.searchBarTextPlaceHolder),
        searchResults: computed(() => state.searchResults),
        categorySearchResults: computed(() => state.categorySearchResults),
        isSearching: computed(() => state.isSearching),
        hideSearchOnMobile: computed(() => state.hideSearchOnMobile),
        searchTerm: computed (() => state.searchTerm),
        setIsSearching,
        openSearch,
        closeSearch,
        toggleSearch,
        rawSearch,
        setSearchResults,
        setCategorySearchResults,
        initCloseOutsideEvents,
        setSearchTerm,
        refreshSearchResults,
    };
}
